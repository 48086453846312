@import "config/grid.yaml";
@import "config/templates.yaml";
@import "config/theme.yaml";
@import "config/woocommerce.yaml";
@import "~@nematis/wp-alpha/assets/sass/abstracts/yaml-variables";
@import "assets/sass/init";
//
@import "init";

// === WP ALPHA ASSETS
@import "~@nematis/wp-alpha/assets/sass/main";

// === LAYOUT
@import "layout/global";
@import "layout/footer";
@import "layout/navigations";

// === COMPONENTS
@import "components/buttons";

.category-training {
  .date,
  ul.post-metas li:first-child {
    display: none;
  }
}
