footer {
  .footer-logo {
    max-width: 150px;
  }
  .opening-hours-list {
    @include media-breakpoint-up($desktop-breakpoint) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }
}
